<template>
  <div class="login_wrap">
    <div class="top">
      <div class="logo" @click="$router.push('/index/home')">
        <img :src="logo" alt="" />
      </div>
      <div>
        <img style="width: 20px; height: 20px; margin-right: 10px" src="@/assets/img/login/cal.png" alt="" />
        <span>客服电话：{{ info.site_fwdh }}</span>
      </div>
    </div>
    <div class="center" @click.stop="click(1)"  :style="{ backgroundImage: `url(${imgUrl})` }">
      <!-- v-on:click.stop="click(1)" -->
      <div class="login_box"  @click.stop="errclick(1)"  >
        <div class="login_top" >
          <div class="login_title" v-if="type == 101">用户登录</div>
          <div class="login_title" v-if="type == 102">找回密码</div>
          <div class="login_title" v-if="type == 103">扫码登录智联科创</div>
          <div class="img_box">
            <div class="sanjiao sanjiao1" v-if="type == 101">
              <img src="@/assets/img/login/sanjiao.png" alt="" />
              <span>手机扫码登录更安全</span>
            </div>
            <div class="sanjiao" v-if="type == 103">
              <img src="@/assets/img/login/sanjiao.png" alt="" />
              <span>账号、密码登录</span>
            </div>
            <div class="img" @click="change">
              <img v-if="type == 101" src="@/assets/img/login/code.png" alt="" />
              <img v-if="type == 103" src="@/assets/img/login/mima.png" alt="" />
            </div>
          </div>
        </div>
        <div class="code_wrap" v-if="type == 101" style="justify-content: flex-start">
          <div class="input_wrap" style="margin-top: 40px">
            <img style="width: 20px; height: 20px" src="@/assets/img/login/user.png" />
            <input placeholder="请输入用户名" v-model="name" v-on:keyup.enter="handleLogin" />
          </div>
          <div class="input_wrap">
            <img style="width: 20px; height: 20px" src="@/assets/img/login/suo.png" />
            <input placeholder="请输入密码" type="password" v-model="pass" v-on:keyup.enter="handleLogin" />
          </div>
          <div class="btn" @click="handleLogin">立即登录</div>
          <div class="forget">忘记密码？<span class="c_p" @click="type = 102">找回密码</span></div>
        </div>
        <div class="code_wrap code_wrap2" v-if="type == 102" style="justify-content: flex-start">
          <div class="input_wrap" style="margin-top: 20px">
            <img style="width: 20px; height: 20px" src="@/assets/img/login/user.png" />
            <input placeholder="请输入用户名" autocomplete="new-password" v-model="p.name" />
          </div>
          <div class="input_wrap">
            <i class="el-icon-mobile"></i>
            <input placeholder="请输入手机号" autocomplete="new-password" v-model="p.phone" />
            <span class="c_p" @click="getCode">验证码</span>
          </div>
          <div class="input_wrap">
            <i class="el-icon-key"></i>
            <input placeholder="请输入验证码" autocomplete="new-password" v-model="p.vcode" />
          </div>
          <div class="input_wrap">
            <img style="width: 20px; height: 20px" src="@/assets/img/login/suo.png" />
            <input placeholder="请输入新密码" autocomplete="new-password" type="password" v-model="p.password" />
          </div>
          <div class="btn" @click="findPass">找回密码</div>
        </div>
        <div class="code_wrap" v-if="type == 103">
          <div class="code">
            <!-- <img src="" alt=""> -->
          </div>
          <p>打开手机 <span>智联科创APP</span> 扫描二维码</p>
        </div>
        <div class="login_bottom">
          <div class="left">
            <div class="login_weixin">
              <img src="@/assets/img/login/login_weixin.png" alt="" />
            </div>
            <span>微信登录</span>
          </div>
          <div class="right" @click="handleRegister">
            <span>立即注册</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_inner">
        <div class="info_item">
          <div v-for="item in arr" :key="item.id">
            <span>{{ item.title }}：</span>
            <span class="cont" @click="click(item)">{{ item.view }}</span>
          </div>
        </div>
        <p>{{ info.company_ts }}</p>
        <div style="display: flex; justify-content: space-between">
          <p>{{ info.company_sm }}</p>
          <p>公司地址：{{ info.xsdwdz }}</p>
        </div>
        <div style="display: flex; justify-content: space-between">
          <p>{{ info.copy_right }}</p>
          <p>
            技术支持：<span style="color: #b2b4c4">{{ info.xsdwmc }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
import { LsetItem } from "@/utils/storage";
import { handleJump } from "@/utils";
export default {
  data() {
    return {
      type: 101, //101用户登录  102找回密码  103扫码
      name: "", //账号
      pass: "", //密码
      imgUrl: "",
      logo: "",
      info: {},
      handleJump,
      p: {
        name: "", //用户登录名
        phone: "", //手机号
        vcode: "", //验证码
        password: "", //新密码
      },
      loot:{},
      arr: [],
    };
  },
  created() {
    this.$api("login.getAD", { adv_id: "7" }).then((res) => {
      this.imgUrl = res.data.adv[0].image.url;
      this.loot = res.data.adv[0];
    });
    this.$api("home.getNavInfo").then((res) => {
      this.logo = res.data.settingImg.wz_logo;
    });
    this.$api("home.getFoot").then((res) => {
      this.info = res.data;
    });
    this.$api("home.getQualifications").then((res) => {
      this.arr = res.data;
    });
  },
  methods: {
    click(){
      this.$api("home.getClick", { adv_id: this.loot.id })
      handleJump(this.loot.jump.web);
    },
    errclick(){
      // 阻止事件
      // console.log(456123);
    },
    handleLogin() {
      if (!this.name) return;
      if (!this.pass) return;
      this.$api("login.getLogin", {
        login_type: "101", //账号密码登录
        name: this.name,
        pass: this.pass,
        password: md5(this.pass), //密码MD5加密
      }).then((res) => {
        LsetItem("token", res.data.token);
        LsetItem("info", res.data);
        this.$store.dispatch("getBadge");
        this.$router.push("/");
      });
    },
    getCode() {
      if (!this.p.phone) return;
      this.$api("login.getCode", {
        code_type: "103", //103找回密码
        phone: this.p.phone,
      }).then(() => {
        this.$message.success("验证码已发送");
      });
    },
    findPass() {
      if (!this.p.name || !this.p.phone || !this.p.vcode || !this.p.password) return;
      this.$api("login.findPass", this.p).then(() => {
        this.$message.success("已重置密码，请重新登录");
        setTimeout(() => {
          this.type = 101;
        }, 1000);
      });
    },
    handleRegister() {
      this.$router.push("/register");
    },
    change() {
      this.type = this.type == 101 ? 103 : 101;
    },
  },
};
</script>

<style lang="less" scoped>
.login_wrap {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .top {
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 38px 0 22px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    .logo {
      width: 243px;
      height: 67px;
    }
  }
  .center {
    flex: 1;
    background-size: cover;
    background-position: center center;
    position: relative;
    .login_box {
      position: absolute;
      top: 80px;
      right: 158px;
      width: 400px;
      height: 463px;
      background: #ffffff;
      box-shadow: 0px 0px 40px 0px rgba(226, 238, 255, 0.5);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      .login_top {
        padding: 0 30px;
        position: relative;
        .login_title {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          height: 100px;
          line-height: 140px;
          border-bottom: 1px solid #eeeeee;
        }
        .img_box {
          position: absolute;
          right: 5px;
          top: 5px;
          display: flex;
          cursor: pointer;
        }
        .sanjiao {
          margin-top: 10px;
          width: 124px;
          height: 25px;
          position: relative;
          img {
            position: absolute;
          }
          span {
            position: absolute;
            top: 3px;
            left: 6px;
            font-size: 14px;
            font-weight: 400;
            color: @priceRed;
          }
        }
        .sanjiao1 {
          width: 147px;
        }
        .img {
          width: 71px;
          height: 71px;
        }
      }
      .code_wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .code {
          width: 145px;
          height: 142px;
          background-color: #eee;
        }
        p {
          margin-top: 40px;
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          span {
            color: @themeColor;
          }
        }
        .input_wrap {
          width: 310px;
          height: 40px;
          background: #f8f8f8;
          border: 1px solid #ededed;
          border-radius: 20px;
          padding: 10px 20px;
          box-sizing: border-box;
          display: flex;
          margin-bottom: 25px;
          input {
            border: none;
            background: #f8f8f8;
            flex: 1;
            margin-left: 12px;
            outline: none;
          }
          .el-icon-mobile,
          .el-icon-key {
            font-size: 20px;
            color: @themeColor;
          }
        }
        .btn {
          width: 310px;
          height: 40px;
          background: @themeColor;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
        .forget {
          width: 310px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          span {
            color: @themeColor;
          }
        }
      }
      .code_wrap2 {
        .input_wrap {
          margin-bottom: 18px;
        }
      }
      .login_bottom {
        width: 400px;
        height: 60px;
        background: #f7f7f7;
        border-radius: 0 0 12px 12px;
        padding: 0 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          .login_weixin {
            width: 22px;
            height: 22px;
            margin-right: 9px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          color: @themeColor;
          line-height: 1;
          cursor: pointer;
          span {
            margin-right: 6px;
          }
        }
      }
    }
  }
  .bottom {
    min-height: 150px;
    background: #2c2f3a;
    padding-top: 39px;
    padding-bottom: 20px;
    box-sizing: border-box;
    color: #fffefe;
    font-size:13px;
    line-height: 30px;
    .bottom_inner {
      width: 1200px;
      margin: 0 auto;
      .info_item {
        display: flex;
        flex-wrap: wrap;
        .cont {
          margin-right: 15px;
          padding-right: 15px;
          color: #b2b4c4;
          border-right: 1px solid #fffefe;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
